// Contact.tsx
import { Box, Heading } from "@chakra-ui/react";

const Contact = () => {
  return (
    <Box>
      <Heading>Hello, I'm Your Name Contact</Heading>
      {/* Other content */}
    </Box>
  );
};

export default Contact;